<template lang="pug">
      vx-card
        .grid-layout-container.alignment-block(style="width:100%;")
          vs-row(vs-w="12")
            vs-col(vs-type="flex", vs-justify="center", vs-align="center", vs-lg="4", vs-sm="4", vs-xs="12")
              img.responsive.rounded(:src="getFullpath(aProduct.mainImage)", alt="user-upload", style="width:120px;")
              br
            vs-col(vs-type="flex", vs-justify="center", vs-align="flex-start", vs-lg="4", vs-sm="4", vs-xs="12")
              .grid-layout-container.alignment-block(style="width:100%;")
                vs-row(vs-w="12")
                  vs-col(vs-type="flex", vs-justify="flex-start", vs-align="flex-start", vs-lg="12", vs-sm="12", vs-xs="12")
                    span(@click="editItem(aProduct.productGroupId)", style="color: #2372CE; font-weight:bold; font-size:18px; cursor:pointer;") {{ aProduct.name }}
                vs-row(vs-w="12")
                  vs-col(vs-type="flex", vs-justify="flex-start", vs-align="flex-start", vs-lg="12", vs-sm="12", vs-xs="12")
                    b รหัส : {{ aProduct.code }}
                vs-row(vs-w="12")
                  vs-col(vs-type="flex", vs-justify="flex-start", vs-align="flex-start", vs-lg="12", vs-sm="12", vs-xs="12")
                    b ประเภท : {{ getCategoryName(aProduct.category) }}
                //- vs-row(vs-w="12")
                //-   vs-col(vs-type="flex", vs-justify="flex-start", vs-align="flex-start", vs-lg="12", vs-sm="12", vs-xs="12")
                //-     b สี : {{ aProduct.color }}
                vs-row(v-if="aProduct.group === 'm'", vs-w="12")
                  .sm-btn-yellow m
                vs-row(vs-w="12")
                  vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="6", vs-sm="6", vs-xs="6")
                    .sm-btn-green(@click="backtodetail()")
                      | กลับรายละเอียดสินค้า
                  vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="6", vs-sm="6", vs-xs="6")
                    .sm-btn-black(@click="backtostock()")
                      | กลับไปหน้าสต๊อค
            vs-col(vs-type="flex", vs-justify="flex-start", vs-align="flex-start", vs-lg="4", vs-sm="4", vs-xs="12")
              vs-row(vs-w="12")
                vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="6", vs-sm="6", vs-xs="12", vs-offset="6")
        .grid-layout-container.alignment-block(style="vertical-align:text-top ;width:100%;")
          // IMAGE UPLOAD COMPONENT
          vs-row(vs-w="12")
            image-upload(:productgroupid="productgroupid")

</template>
<script>
import shapeFormat from "@/mixins/shapeFormat.js";
import ImageUpload from "./Components/ImageUpload.vue";
export default {
  mixins: [shapeFormat],
  data() {
    return {
      itemId: "",
      // aProduct: {},
      categories: []
    };
  },
  async mounted() {
    // this.aProduct = await this.getOne("/api/productitem/" + this.itemId);
    this.categories = await this.getList("/api/category");
    // console.log("NEW IN PRODUCT DETAIL >>> ", this.aProduct.productItemId);
  },
  async created() {
    // INIT productGroup
    this.productgroupid = this.$route.params.productgroupid;
    await this.$store.dispatch(
      "productGroup/set",
      this.productgroupid
    );

  },
  computed: {
    aProduct : {
      get(){
        return this.$store.getters["productGroup/productGroup"];

      },
      set(){

      }
    }
  },
  methods: {
    backtostock() {
      window.open("/backoffice/stock", "_blank");
    },
    backtodetail() {
      window.open("/backoffice/updateitem/" + this.productgroupid, "_blank");
    },
    getCategoryName(codename) {
      var c = this.categories.find(x => x.code === codename);

      var categoryName = "";
      if (c === null || c === undefined) {
        categoryName = "ไม่มี";
      } else {
        categoryName = c.description;
      }

      return categoryName;
    },
    editItem(productItemId) {
      window.open("/backoffice/updateitem/" + productItemId, "_blank");
      // this.$router.push("/backoffice/create-item/" + productItemId);
    },
    getFullpath(mainImage) {
      if (
        mainImage === undefined ||
        mainImage === null ||
        mainImage === "" ||
        mainImage === this.$store.state.imageStore
      ) {
        return this.$store.state.defaultImage;
      } else {
        return this.$store.state.imageStore + mainImage;
      }
    }
  },
  components: {
    ImageUpload
  }
};
</script>

<style></style>
